<template>
  <div id="viewContainer">
    <div class="view-header" v-if="contractVersionDetail">
      <div class="view-header-top">
        <p class="vht-name">{{contractVersionDetail.title }}</p>
      </div>
      <div class="view-header-bottom" >
<!--        <div class="vhb-item vhb-status">-->
<!--          <div>-->
<!--            <div v-if="contractVersionDetail.status == ContractApprovalStatus.DRAFT">-->
<!--                    <span class="stage-circle stage-gray"></span-->
<!--                    ><span>草稿</span>-->
<!--            </div>-->

<!--              <div v-else-if="contractVersionDetail.status ==-->
<!--              ContractApprovalStatus.LAUNCH_READY_TO_SIGN">-->
<!--                      <span class="stage-circle stage-yellow"></span-->
<!--                      ><span>审批中</span>-->
<!--              </div>-->
<!--            <div-->
<!--                v-else-if="-->
<!--                      contractVersionDetail.status ==-->
<!--                      ContractApprovalStatus.LAUNCH_READY_TO_SIGN_PASSED-->
<!--                    "-->
<!--            >-->
<!--                    <span class="stage-circle stage-purple"></span-->
<!--                    ><span>定稿</span>-->
<!--            </div>-->
<!--            <div-->
<!--                v-else-if="-->
<!--                      contractVersionDetail.status ==-->
<!--                      ContractApprovalStatus.LAUNCH_READY_TO_SIGN_REFUSED-->
<!--                    "-->
<!--            >-->
<!--                    <span class="stage-circle stage-red"></span-->
<!--                    ><span>定稿审批驳回</span>-->
<!--            </div>-->
<!--              <div  v-else-if="-->
<!--                      contractVersionDetail.status ==-->
<!--                      ContractApprovalStatus.LAUNCH_SIGNED-->
<!--                    ">-->
<!--                      <span class="stage-circle stage-yellow"></span-->
<!--                      ><span>审批中</span>-->
<!--              </div>-->

<!--            <div-->
<!--                v-else-if="-->
<!--                      contractVersionDetail.status ==-->
<!--                      ContractApprovalStatus.LAUNCH_SIGNED_PASSED-->
<!--                    "-->
<!--            >-->
<!--                    <span class="stage-circle stage-green"></span-->
<!--                    ><span>已签署</span>-->
<!--            </div>-->
<!--            <div-->
<!--                v-else-if="-->
<!--                      contractVersionDetail.status ==-->
<!--                      ContractApprovalStatus.LAUNCH_SIGNED_REFUSED-->
<!--                    "-->
<!--            >-->
<!--                    <span class="stage-circle stage-red"></span-->
<!--                    ><span>签署审批驳回</span>-->
<!--            </div>-->
<!--            <div-->
<!--                v-else-if="-->
<!--                      contractVersionDetail.status == ContractApprovalStatus.CANCELED-->
<!--                    "-->
<!--            >-->
<!--              1 <span class="stage-circle stage-black"></span-->
<!--            ><span>已取消</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="vhb-item vhb-isNew">{{contractVersionDetail.latestVersion ? '最新版本' : '旧版'}}</div>
        <div class="vhb-item vhb-lastUpdateTime"><span>最近一次修改 : </span><span>{{contractVersionDetail.lastUpdateTime}}</span></div>
      </div>
    </div>
    <div class="view-content" :style="{minHeight:VCMinHeight}">
      <PdfPreview
          :contract-id="contractId"
          :version-id="versionId"
          v-if="contractId && versionId"
          ref="pdf-preview"
          :default_scale="require('../../../components/common/pdfView').ScaleSpecialValue.PAGE_WIDTH"
          :previewSuccess="pdfPreviewSuccess"
      ></PdfPreview>
  </div>
    <div class="not-login" v-if="notLogin">
      <h2>未登录</h2>
      <el-button @click="goToWechatAutoLogin">登录</el-button>

      <pre style="word-break: break-all;white-space: pre-wrap;padding: 20px;text-align: left">
        uid : {{getCookie('uid')}}
        sid : {{getCookie('sid')}}
        location.href : {{ location.href}}
      </pre>
    </div>

  </div>
</template>

<script>
import {colorLog,getCookie} from "../../../util";
import PdfPreview from "../../../components/PdfPreview";
import {getContractVersionDetail} from "../../../api/api";
import {ContractApprovalStatus} from "../../../constant/contract";
import {ElMessage} from "element-plus";
//这个页面专门用于微信浏览器环境
export default {
  name: "viewVersionFile",
  data(){
    return {
      versionCode:null,
      contractVersionDetail:null,
      contractId:null,
      versionId:null,
      ContractApprovalStatus:ContractApprovalStatus,
      VCMinHeight:0 + 'px',
      notLogin:false,
      getCookie,
      location:window.location
    }
  },
  methods:{
    pdfPreviewSuccess(pdfContainerId){
      const pdfContainer = document.getElementById(pdfContainerId);

      const height = Number(getComputedStyle(pdfContainer).height.slice(0,-2))
      this.VCMinHeight = height + 'px';
    },
    async getContractVersionDetail(){
      if(!this.versionCode){
        console.error('versionCode 为空')
      }
      const res = await getContractVersionDetail(this.versionCode);
      if(res.data?.code == 401){
        this.goToWechatAutoLogin();
        // this.notLogin = true;
      }else if(res.data?.code == 402){
        //没有权限
        this.$router.push({
          name:'resolveReject',
          query:{
            type:'noApproval'
          }
        })
      } else if(res.data.code == 0 || res.data.code == 200){
        const { contractId,versionId} = this.contractVersionDetail = res.data.data;
        this.contractId = contractId;
        this.versionId = versionId;
      }
    },
    //没有登录,到微信自动登录页面
    goToWechatAutoLogin(){
      this.$router.push({
        name:'weChatAuth',
        query:{
          to:location.href
        }
      })
    }
  },
  created() {
    this.versionCode = this.$route.params.versionCode;
    this.getContractVersionDetail();

  },
  components:{PdfPreview}
}
</script>

<style scoped>
/*@import "../../../assets/css/contract.css";*/
p{
  margin: 0;
}
#viewContainer{
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  position: relative;
}
.view-header{
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  background: rgb(219 219 219);
  position: sticky;
  top: 0;
  z-index: 1;
  left: 0;
}
.view-header-top{
  width: 100%;
}
.view-content{
  width: 100%;
}
.vht-name{
  width: 100%;
  font-size: 4vw;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  font-weight: bold;
  text-align: left;
}
.view-header-bottom{
  width: 100%;
  display: flex;
  font-size: 3vw;
}
.vhb-item{
  height: 5.4vw;
  line-height: 5.4vw;
}
.vhb-item{
  padding-right: 5px;
}
.vhb-item:nth-child(n+2){
  padding-left:5px;
  position: relative;
}
.vhb-item:nth-child(n+2)::before{
  position: absolute;
  content: '';
  height: 80%;
  width: 1px;
  background: #ccc;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

</style>
